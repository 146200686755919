import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import store from '../store/index'

const routes = [{
		path: '/',
		name: 'layout',
		redirect: '/home',
		component: () => import('@/views/layout.vue'),
		children: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/home.vue'),
			meta: {
				anonymous: true,
			}
		}]
	}
]

const router = createRouter({
	history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
	routes
})

function getQueryString(url, name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url, name) || [, ""])[1]
		.replace(/\+/g, '%20')) || null
}

router.beforeEach((to, from, next) => {

	//找不到页面
	// if (to.matched.length == 0) {
	// 	return next({
	// 		path: '/404'
	// 	});
	// }

	let cardTitle = '';

	if (to.meta.title) {
		cardTitle = to.meta.title;
	}

	if (to.query.type) {
		switch (to.query.type) {
			case "1":
				cardTitle = "升学卡（选科+提分）";
				break;
			case "2":
				cardTitle = "升学卡（提分+志愿）";
				break;
			case "3":
				cardTitle = "升学卡（选科+提分+志愿）";
				break;
			case "4":
				cardTitle = "升学卡（选科+志愿）";
				break;
		}
	}

	store.commit('set_cardTitle', cardTitle);
	document.title = cardTitle;
	return next();

})

router.afterEach((to, from) => {

})


export default router