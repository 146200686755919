import {
	createApp
} from 'vue'
import App from './App.vue'
var app = createApp(App);

import './assets/css/common.css'

import util from './util/util.js'
app.config.globalProperties.$util = util

import router from './router'
app.use(router);

import store from './store/index.js'
app.use(store)
app.config.globalProperties.$store = store


import { Swipe, SwipeItem } from 'vant';
app.use(Swipe);
app.use(SwipeItem);
import { Lazyload } from 'vant';
app.use(Lazyload, {
  lazyComponent: true,
});
import { Collapse, CollapseItem } from 'vant';
app.use(Collapse);
app.use(CollapseItem);

// js通用方法
import api from './api/api.js'
app.config.globalProperties.$api = api

import base from './assets/script/common.js'
import http from './api/http.js'
app.config.globalProperties.$http = http
app.config.globalProperties.$base = base

import VueCookies from 'vue-cookies'
app.config.globalProperties.$cookies = VueCookies

app.mount("#app");
