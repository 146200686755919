import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, null, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
      _: 2
    }, 1024)]),
    _: 1
  });
}