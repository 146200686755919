import {
	createStore
} from "vuex"
import createPersistedState from "vuex-persistedstate";
export default createStore({
	plugins: [createPersistedState()],
	state: {
		loadingStatus: false,
		cardTitle: ''
	},
	mutations: {
		set_loadingStatus(state, data) {
			state.loadingStatus = data;
		},
		set_cardTitle(state, data) {
			state.cardTitle = data;
		},
	},
	getters: {
		get_loadingStatus: (state) => () => {
			return state.loadingStatus;
		},
		get_cardTitle: (state) => () => {
			return state.cardTitle;
		},
	},
	actions: {
		onLoading(context, flag) {
			context.commit("updateLoadingState", flag); //调用方式 store.dispatch('onLoading',data)
		}
	}
})